import React, { createContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

interface Props {
    children?: React.ReactNode;
}

type LocalAttrs = {
    attributes: {
        key: string;
        value: string;
        locale: string;
    };
};

type Asset = {
    key: string;
    value: {
        data: {
            value: string;
        };
    };
    locale: string;
    localizations: {
        data: LocalAttrs[];
    };
};

type ContentData = {
    allStrapiNanodevAsset: {
        nodes: Asset[];
    };
};

interface contextProps {
    lang: string
    translate: (key: string) => string
    setLanguage: React.Dispatch<string>
}

export const ContentContext = createContext<contextProps>({
    lang: '',
    translate: (key: string) => key,
    setLanguage: () => null
})

const Layout = ({ children }: Props) => {
    const [lang, setLang] = useState<string>('')

    const setLanguage = (lang: string) => {
        localStorage.setItem('language', lang)
        setLang(lang)
    }

    const getLang = (): string | null => {
        const ln_local =
            typeof window !== "undefined" ? localStorage.getItem("language") : null;
        const SYSTEM_LANGUAGE = ln_local || setDefaultLanguage();
        return SYSTEM_LANGUAGE;
    }

    const setDefaultLanguage = (lang?: string | null): string | null => {
        const systemLanguage =
            typeof window !== "undefined" ? navigator.language.split("-")[0] : "null";
        typeof window !== "undefined"
            ? localStorage.setItem("language", lang || systemLanguage)
            : null;
        return systemLanguage;
    };

    useEffect(() => {
        const lang = getLang();
        if (lang) setLanguage(lang);

    }, []);

    const data: ContentData = useStaticQuery(graphql`
    query Content {
        allStrapiNanodevAsset {
            nodes {
                key
                value {
                data {
                    value
                }
                }
                locale
                localizations {
                data {
                    attributes {
                    key
                    value
                    }
                }
                }
            }
        }
    }
  `);

    const translate = (key: string): string => {

        const asset = data.allStrapiNanodevAsset.nodes.find((a) => a.key === key)

        if (!asset) return "";
        let value = asset.value.data.value;

        if (lang == "fr") {
            const localAttr = asset.localizations.data.find((el) => key == el.attributes.key)
            if (localAttr) value = localAttr.attributes.value;
        } else if (lang == "ar") {
            const localAttrAr = asset.localizations.data.filter((el) => key == el.attributes.key)[1];
            if (localAttrAr) value = localAttrAr.attributes.value;
        } else {
            return value;
        }
        return value;
    }

    return (
        <ContentContext.Provider value={{ lang, translate, setLanguage }}>
            <>
            
              {children}
            
            </>
        </ContentContext.Provider>
    )
};

export default Layout;
