import * as React from 'react'
import { Link } from 'gatsby'
import { I404, I4042 } from '@images/images'
import { ContentContext } from '@components/layouts'
import { useContext } from 'react'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
  const { lang, translate, setLanguage } = useContext(ContentContext)
  return (
    <div className="content-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 Not Found | Nanodev Blog</title>
        <link rel="canonical" href={'https://www.nanodev.ca/404'} />
        <meta
          name="description"
          property="og:description"
          content={'You landed in a void space from Nanodev...'}
        />
      </Helmet>
      <section className="wrapper bg-light">
        <div className="container pt-12 pt-md-14 pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-9 col-xl-8 mx-auto">
              <figure className="mb-10">
                <img className="img-fluid" src={I404} srcSet={I4042} alt="" />
              </figure>
            </div>

            <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
              <h1 className="mb-3">{translate('Oops ! Page Not Found.')}</h1>
              <p className="lead mb-7 px-md-12 px-lg-5 px-xl-7">
                {translate(
                  'The page you are looking for is not available or has been moved. Try a different page or go to homepage with the button below.'
                )}
              </p>
              <a href="/" className="btn btn-primary rounded-pill">
                {translate('Return to Home')}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default NotFoundPage
